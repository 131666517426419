import {NotificationType} from "../../../../constants/constants";
import {navigate} from "../../../../constants/routes";
import {resolveStatusAndAddToSummary} from "./Utils";
import {ApplicationActions} from "../../../../player/ApplicationActions";

export const processOverrideStatusResponse = (updateSuccess, appActions, scannableId, targetStatus, selectedCategory,
                                              driverSummary, isResolvingPackages, packagesCount,
                                              currentPackageIndex, debriefDriverActions, props, dispatchPlayer, packageFreeCount) => {
    if (updateSuccess) {
        appActions.setNotification(NotificationType.SUCCESS,
            props.intl.formatMessage(
                {id: "update_status_success"},
                {trackingId: scannableId.slice(-4)}
            ))
        debriefDriverActions.setDriverSummary(resolveStatusAndAddToSummary(scannableId, driverSummary, targetStatus, selectedCategory, isResolvingPackages))
        if (isResolvingPackages) {
            if (packagesCount === currentPackageIndex + 1) {
                if (packageFreeCount.length > 0) {
                    props.history.push(navigate.DUNNAGE_RECEIVE)
                } else {
                    dispatchPlayer({
                        type: ApplicationActions.OVERRIDE_SELECTED,
                        data: {category: selectedCategory, error: false, next: navigate.RETURN_COMPLETE}
                    });
                    props.history.push(navigate.RETURN_COMPLETE)
                }
            } else {
                dispatchPlayer({
                    type: ApplicationActions.OVERRIDE_SELECTED,
                    data: {category: selectedCategory, error: false, next: navigate.PACKAGE_OVERVIEW}
                });
                debriefDriverActions.setUnresolvedPackages();
                props.history.push(navigate.PACKAGE_OVERVIEW)
            }
        } else {
            dispatchPlayer({
                type: ApplicationActions.OVERRIDE_SELECTED,
                data: {category: selectedCategory, error: false, next: navigate.RECEIVE_PACKAGE}
            });
            props.history.push(navigate.RECEIVE_PACKAGE)
        }
    } else {
            dispatchPlayer({
                type: ApplicationActions.OVERRIDE_SELECTED,
                data: {category: selectedCategory, error: true}
            });
            appActions.setNotification(NotificationType.ERROR, props.intl.formatMessage({
                id: "status_update_failed_message"
            }))
        }
}
