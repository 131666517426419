import React, {useContext, useEffect, useState} from "react"
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {DetailsCardView, Loader} from "@amzn/dolphin-uiweb-framework";
import {DebriefDriverContext} from "../context/DebriefDriverContext";
import {AppContext} from "../../appContext/context/AppContext";
import NotificationView from "../../appContext/views/NotificationView";
import {ReconciliationStatus} from "./DriverSummaryView/Constants";
import {navigate} from "../../constants/routes";
import {FormattedMessage, injectIntl} from "react-intl";
import {interceptBackButton, interceptBackButtonStop} from "../../Handler/BackPressHandler";
import {NotificationType} from "../../constants/constants";
import {ResponseStatus} from "../../receiveContainer/view/Constants";
import Divider from "@amzn/meridian/divider";

const DunnageReceiveView = (props) => {
    const {
        state: {
            driverSummary: {[ReconciliationStatus.PACKAGE_FREE_DELIVERED]: packages = []} = {},
            transporter: {transporterId = ""} = {},
            requestCompleted, isLoading, dunnageDetails: dunnageResponseStatus = {}
        }, debriefDriverActions
    } = useContext(DebriefDriverContext)

    const {appActions} = useContext(AppContext);
    const [data, setData] = useState([])

    const dunnageReturned = () => {
        const requestData = {
            transporterId: transporterId,
            packageFreeDeliveries: packages.length,
            noOfPackagingReceived: packages.length
        }
        debriefDriverActions.dunnageReceive(requestData, debriefDriverActions, appActions.setNotification)
    }


    const dunnageNotReturned = () => {
        let success = navigate.DUNNAGE_RECEIVE_SUCCESS
        success = success.replace(":return","false")
        props.history.push(success)

    }

    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        setData([{title: packages.length.toString(), description: <FormattedMessage id="dunnage_receive" />}])
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    useEffect(() => {
        if (requestCompleted) {
            debriefDriverActions.resetRequest()
            if (dunnageResponseStatus.responseStatus  === ResponseStatus.SUCCESS) {
                props.history.push(navigate.DUNNAGE_RECEIVE_SUCCESS)
            } else {
                appActions.setNotification(NotificationType.ERROR,
                    props.intl.formatMessage(
                        {id: "dunnage_service_error"}
                    ))
            }
        }
    }, [requestCompleted]);

    const displayDefaultComponent = () => {
        return (
            <Column height="100%" heights="fill">
                <Column spacingInset="medium">
                    <NotificationView/>
                    <Text alignment="center" type="h300"><FormattedMessage id="dunnage_receive_title" /></Text>
                    <Divider size="small"/>
                    <Text alignment="center" type="b300"><FormattedMessage id="dunnage_receive_guidance" /></Text>
                    <DetailsCardView snapshots={data}/>
                </Column>
                <Column alignmentHorizontal="center" alignmentVertical="bottom" spacingInset="medium">
                    <Row width="100%" widths="fill" alignmentVertical="bottom">
                        <Button size="large" onClick={dunnageReturned} type="primary"><FormattedMessage id="returned_button" /></Button>
                    </Row>
                    <Row width="100%" widths="fill" alignmentVertical="bottom">
                        <Button size="large" onClick={dunnageNotReturned} type="secondary"><FormattedMessage id="not_returned_button" /></Button>
                    </Row>
                </Column>
            </Column>
        )
    }

    return (
        <Column height="100%" heights="fill">
            {isLoading && <Loader/>}
            {displayDefaultComponent()}
        </Column>
    )
}

export default injectIntl(DunnageReceiveView);