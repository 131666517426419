import React, {useContext, useEffect, useState} from "react";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Divider from "@amzn/meridian/divider";
import PackageDetailsSnapshotView from "../../../../common/PackageDetailsSnapshotView";
import Button from "@amzn/meridian/button";
import {ReceivePackageContext} from "../../../context/ReceivePackageContext";
import {skipStatusToSummary} from "./Utils";
import {DebriefDriverContext} from "../../../../debriefDriver/context/DebriefDriverContext";
import {AppContext} from "../../../../appContext/context/AppContext";
import {ValidationUtils} from "../../../../utils/Utils";
import {NotificationType} from "../../../../constants/constants";
import {navigate} from "../../../../constants/routes";
import {createItemDataSnapshot, createPackageDataSnapshot, createPackageTraceSnapshot} from "./Constants";
import {FormattedMessage, injectIntl} from "react-intl";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {PlayerContext} from "../../../../player/PlayerContext";
import {ApplicationActions} from "../../../../player/ApplicationActions";
import {ReconciliationStatus} from "../../../../debriefDriver/views/DriverSummaryView/Constants";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import {ResponseCode} from "../ScanPackageView/Constants";

const PackageDetailsView = (props) => {
    const {
        state: {
            packageData: { userOutput: {responseCode = ""} = {} },
            packageDetails = {}, packageAttributes: {scannableId = "", shipmentType = ""} = {},
            requestCompleted, isLoading
        }, receivePackageActions
    } = useContext(ReceivePackageContext);
    const {state: {isResolvingPackages, currentPackageIndex = 0, packagesCount = 0,
        driverSummary = {},
        driverSummary: {[ReconciliationStatus.PACKAGE_FREE_DELIVERED]: packageFreeDeliveries = []} = {}}, debriefDriverActions} = useContext(DebriefDriverContext);
    const {appActions} = useContext(AppContext);
    const {dispatchPlayer} = useContext(PlayerContext);
    const [data, setData] = useState([])
    const [packageTrace, setPackageTrace] = useState([])
    const [itemData, setItemData] = useState([])

    const shouldDisplayItemDetailsForDamagedPackage = () => {
        return (!isResolvingPackages && responseCode && responseCode === ResponseCode.DAMAGED &&
            ValidationUtils.isNotEmptyObject(packageDetails.items) && packageDetails.items.length !== 0)
    }

    useEffect(() => {
        if (ValidationUtils.isNotEmptyObject(packageDetails)) {
            setData(createPackageDataSnapshot(props.intl, packageDetails))
            if(shouldDisplayItemDetailsForDamagedPackage()) {
                setItemData(createItemDataSnapshot(props.intl, packageDetails))
            }
            const isScheduledDeliveryDisplayEnabled = FeatureManager.isFeatureEnabled(FeatureManager.Features.SCHEDULED_DELIVERY_DISPLAY_ENABLED);
            setData(createPackageDataSnapshot(props.intl, packageDetails, isScheduledDeliveryDisplayEnabled))
            setPackageTrace(createPackageTraceSnapshot(packageDetails.packageUpdateTrace))
        }
        return () => {
            if (ValidationUtils.isNotEmptyObject(packageDetails)) {
                receivePackageActions.resetPackageDetails();
            }
        }
    }, [packageDetails])

    const skipUpdate = () => {
        if (isResolvingPackages) {
            debriefDriverActions.setDriverSummary(skipStatusToSummary(isResolvingPackages, driverSummary, scannableId))
            debriefDriverActions.setUnresolvedPackages();
            if (packagesCount === currentPackageIndex + 1) {
                if (packageFreeDeliveries.length > 0) {
                    props.history.push(navigate.DUNNAGE_RECEIVE)
                } else {
                    dispatchPlayer({type: ApplicationActions.PACKAGE_SKIPPED, data: navigate.RETURN_COMPLETE});
                    props.history.push(navigate.RETURN_COMPLETE)
                }
            } else {
                dispatchPlayer({type: ApplicationActions.PACKAGE_SKIPPED});
                appActions.setNotification(NotificationType.WARNING,
                    props.intl.formatMessage(
                        {id: "package_status_update_skipped"},
                        {trackingId: scannableId.slice(-4)}
                    ))
                props.history.push(navigate.PACKAGE_OVERVIEW)
            }
        }
    }

    useEffect(() => {
        if (requestCompleted) {
            receivePackageActions.resetRequest()
            if (!ValidationUtils.isNotEmptyObject(packageDetails)) {
                appActions.setNotification(NotificationType.ERROR,
                    props.intl.formatMessage({id: "unable_to_fetch_address"}))
            }
        }
    }, [requestCompleted])

    useEffect(() => {
        if (!ValidationUtils.isNotEmptyObject(packageDetails)) {
            let data = {
                scannableId: scannableId,
                shipmentType: shipmentType
            }
            if (responseCode && responseCode === ResponseCode.DAMAGED) {
                data = {...data, additionalFields: "items"}
            }
            receivePackageActions.getPackageDetails(data, receivePackageActions, appActions.setNotification)
        }
    }, [])

    const displayDefaultComponent = () => {
        return (
            <div className="scrollContainer">
                <Column spacingInset="medium">
                    <PackageDetailsSnapshotView packageDetails={data}/>
                    { shouldDisplayItemDetailsForDamagedPackage() === true &&
                        <Column>
                            <Divider size="small"/>
                            <PackageDetailsSnapshotView packageDetails={itemData}/>
                        </Column>
                    }
                    <Column spacing={"none"}>
                        <Text>
                            <FormattedMessage id={"package_history"}/>
                        </Text>
                        <Divider size="small"/>
                    </Column>
                    <PackageDetailsSnapshotView packageDetails={packageTrace}/>
                </Column>
            </div>
        )
    }

    return (
        <Column height={"90%"} alignmentVertical={"bottom"} spacing={"none"} id={"container"}>
            {isLoading && <Loader/>}
            <Column width="100%" spacingInset="medium" spacing={"none"} id={"fix-tracking-id"}>
                <Text type="h200">{scannableId}</Text>
                <Divider size="small"/>
            </Column>
            {displayDefaultComponent()}
            {isResolvingPackages &&
            <Column width="100%" spacingInset="medium" spacing={"none"} id={"footer-btn"}>
                <Button onClick={skipUpdate} size="large" type="secondary">
                    <FormattedMessage id={"btn_skip_status_update"}/>
                </Button>
            </Column>
            }
        </Column>
    )
}

export default injectIntl(PackageDetailsView);
