export const Events = {
    OPERATION_API_CALL: "operation_api_call",
    USER_SCANNED_PACKAGE: "user_scanner_package",
    USER_SCANNED_CONTAINER: "user_scanned_container",
}

// TODO: These values need to be revised after checking with
//  https://code.amazon.com/packages/StationBuddyReturnToStation/blobs/mainline/--/src/main/java/com/amazon/rts/android/data/model/MetricKeys.java
export const Modules = {
    DEBRIEF: "DEBRIEF",
    PWA: "PWA",
    PANDA_TOKEN_EXCHANGE: "PandaTokenExchange",

    GET_DEBRIEF_DETAILS: "getDebriefDetails",
    SEARCH_TRANSPORTER_BY_KEYWORD: "searchTransporterByKeyword",
    GET_TRANSPORTER_BY_BADGE_ID: "getTransporterByBadgeId",
    BULK_RECEIVE_PACKAGE: "bulkReceivePackage",
    DUNNAGE_RECEIVE: "dunnageReceive",

    RECEIVE_CONTAINER: "RECEIVE_CONTAINER",

    RECEIVE_PACKAGE: "receivePackage",
    GET_TARGET_STATUS_LIST: "getTargetStatusList",
    UPDATE_TARGET_STATUS: "updateTargetStatus",
    GET_PACKAGE_DETAILS: "getPackageDetails",

    INVALID_TRACKING_ID: "INVALID_TRACKING_ID",
    ALREADY_PROCESSED: "ALREADY_PROCESSED",
    RECEIVE_FAILED: "RECEIVE_FAILED",
    RECEIVED_WITH_WARNING: "RECEIVED_WITH_WARNING",
    RECIEVED_SUCCESSFULLY: "RECIEVED_SUCCESSFULLY"
}