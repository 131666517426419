import React, {useContext, useEffect, useState} from "react"
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Select, {SelectOption} from "@amzn/meridian/select";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {ReceivePackageContext} from "../../../context/ReceivePackageContext";
import NotificationView from "../../../../appContext/views/NotificationView";
import {CATEGORY_INSTATION, CATEGORY_ONROAD} from "../../../../constants/constants";
import {AppContext} from "../../../../appContext/context/AppContext";
import {DebriefDriverContext} from "../../../../debriefDriver/context/DebriefDriverContext";
import {deliveredToNeighbour} from "./Utils";
import {navigate} from "../../../../constants/routes";
import {processOverrideStatusResponse} from "./Constants";
import {FormattedMessage, injectIntl} from "react-intl";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {PlayerContext} from "../../../../player/PlayerContext";
import {ApplicationActions} from "../../../../player/ApplicationActions";
import {ReconciliationStatus} from "../../../../debriefDriver/views/DriverSummaryView/Constants";
import {PlayerConstants, StatusOverrideOption} from "../../../../player/PlayerConstant";

const SelectOverrideStatusView = (props) => {
    const {
        state: {
            isLoading, overrideCategory: selectedCategory,
            targetList: {targetStatusList: {[selectedCategory]: targetList = [], overrideReasonList = []} = {}} = {},
            packageAttributes: {scannableId = "", shipmentType = ""} = {},
            requestCompleted, updateSuccess
        }, receivePackageActions
    } = useContext(ReceivePackageContext);
    const {
        state: {isResolvingPackages, packagesCount = 0, currentPackageIndex = 0, driverSummary = {},
            driverSummary: {[ReconciliationStatus.PACKAGE_FREE_DELIVERED]: packageFreeCount = []} = {}},
        debriefDriverActions
    } = useContext(DebriefDriverContext);
    const {appActions} = useContext(AppContext);
    const {dispatchPlayer} = useContext(PlayerContext);

    const UPDATE_STATUS = props.intl.formatMessage({id:"btn_update_status_submit"})
    const CONTINUE = props.intl.formatMessage({id:"btn_continue"})

    const [targetStatus, setTargetStatus] = useState(null)
    const [targetReason, setTargetReason] = useState(null)
    const [targetCode, setTargetCode] = useState(null)
    const [buttonStatus, setButtonStatus] = useState({disabled: true})
    const [buttonTitle, setButtonTitle] = useState(UPDATE_STATUS)

    const [targetStatusList, setTargetStatusList] = useState([])
    const [targetReasonList, setTargetReasonList] = useState([])
    const [targetCodeList, setTargetCodeList] = useState([])

    const cancel = () => {
        dispatchPlayer({type: ApplicationActions.STATUS_OVERRIDE, data:{ operation: StatusOverrideOption.CANCEL, view: PlayerConstants.SELECT_STATUS}})
        props.history.push(navigate.PACKAGE_OVERVIEW)
    }

    const updateStatus = () => {
        const targetStatusData = {
            deliveredToNeighbor: false,
            overrideReason: targetCode,
            scannableId: scannableId,
            shipmentType: shipmentType,
            targetReason: targetReason,
            targetStatus: targetStatus,
        }
        receivePackageActions.updateStatusData(targetStatusData)
        if (deliveredToNeighbour(targetStatus, targetReason)) {
            props.history.push(navigate.RECIPIENT_INFORMATION)
        } else {
            receivePackageActions.updateTargetStatus(receivePackageActions, appActions.setNotification)
        }
    }

    const updateTargetReasonList = (selectedStatus) => {
        setTargetReason(null)
        setTargetStatus(selectedStatus)
        setTargetReasonList(
            targetList[selectedStatus].map((data) =>
                <SelectOption label={data} value={data}/>
            )
        )
    }

    useEffect(() => {
        if (requestCompleted) {
            receivePackageActions.resetRequest()
            processOverrideStatusResponse(updateSuccess, appActions, scannableId, targetStatus, selectedCategory, driverSummary,
                isResolvingPackages, packagesCount, currentPackageIndex, debriefDriverActions, props, dispatchPlayer, packageFreeCount)
        }
    }, [requestCompleted])

    useEffect(() => {
        switch (selectedCategory) {
            case CATEGORY_ONROAD:
                if (targetStatus && targetReason && targetCode) {
                    setButtonStatus(null)
                } else {
                    setButtonStatus({disabled: true})
                }
                break;
            case CATEGORY_INSTATION:
                if (targetStatus && targetReason) {
                    setButtonStatus(null)
                } else {
                    setButtonStatus({disabled: true})
                }
                break;
            default:
                break;
        }
        if (deliveredToNeighbour(targetStatus, targetReason)) {
            setButtonTitle(CONTINUE)
        } else {
            setButtonTitle(UPDATE_STATUS)
        }
    }, [targetStatus, targetReason, targetCode])

    useEffect(() => {
        if (!requestCompleted) {
            setTargetStatusList(
                Object.keys(targetList).map((data) =>
                    <SelectOption label={data} value={data}/>
                )
            )
            setTargetCodeList(
                overrideReasonList.map((data) =>
                    <SelectOption label={data} value={data}/>
                )
            )
        }
    }, [])

    const displayRegularComponent = () => {
        return (
            <Column height="100%" heights="fill">
                <Column spacingInset="medium" spacing="450">
                    <NotificationView/>
                    <Column>
                        <Text type="h100">
                            <FormattedMessage id={"select_target_status_message"} />
                        </Text>
                    </Column>
                    <Column spacing="200">
                        <Text type="b200">
                            <FormattedMessage id={"target_status_title"} />
                        </Text>
                        <Select value={targetStatus} onChange={updateTargetReasonList} placeholder="Select...">
                            {targetStatusList}
                        </Select>
                    </Column>
                    <Column spacing="200">
                        <Text type="b200">
                            <FormattedMessage id={"target_reason_title"} />
                        </Text>
                        <Select value={targetReason} onChange={setTargetReason} placeholder="Select...">
                            {targetReasonList}
                        </Select>
                    </Column>
                    {(CATEGORY_ONROAD === selectedCategory) && <Column spacing="200">
                        <Text type="b200">
                            <FormattedMessage id={"override_reason_title"} />
                        </Text>
                        <Select value={targetCode} onChange={setTargetCode} placeholder="Select...">
                            {targetCodeList}
                        </Select>
                    </Column>}
                </Column>

                <Column alignmentHorizontal="center" alignmentVertical="bottom" spacingInset="medium">
                    <Row width="100%" widths="fill" alignmentVertical="bottom">
                        <Button onClick={updateStatus} size="large"
                                type="primary" {...buttonStatus}>{buttonTitle}</Button>
                    </Row>
                    <Row width="100%" widths="fill" alignmentVertical="bottom">
                        <Button onClick={cancel} size="large" type="secondary">
                            <FormattedMessage id="btn_cancel" />
                        </Button>
                    </Row>
                </Column>
            </Column>
        )
    }
    return (
        <Column height="100%" heights="fill">
            {isLoading && <Loader/>}
            {displayRegularComponent()}
        </Column>
    )
}

export default injectIntl(SelectOverrideStatusView);
