import React, {useContext, useEffect} from "react";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {DebriefDriverContext} from "../context/DebriefDriverContext";
import {navigate} from "../../constants/routes";
import {FormattedMessage} from "react-intl";
import {AppContext} from "../../appContext/context/AppContext";
import {interceptBackButton, interceptBackButtonStop} from "../../Handler/BackPressHandler";
import imageSuccess from "../../../images/ic_checkmark.png";
import imageWarning from "../../../images/ic_alert_warning.png";


const DunnageReceiveSuccessView = (props) => {
    const {debriefDriverActions} = useContext(DebriefDriverContext)

    const driverSummary = () => {
        debriefDriverActions.setDebriefComplete()
        props.history.push(navigate.DRIVER_SUMMARY)
    }

    const {appActions} = useContext(AppContext)
    const returnPackage = props.match.params.return


    const onBackPressed = () => {
        appActions.openBackDrop()
    }

    useEffect(() => {
        appActions.setBackPress(onBackPressed)
        interceptBackButton();
        return () => {
            appActions.resetBackPress()
            interceptBackButtonStop()
        }
    }, [])

    return (
        <Column height="100%" heights="fill" spacingInset="medium">
            { returnPackage === 'false' ?
                <Column alignmentHorizontal="center" spacingInset="small">
                    <img src={imageWarning} alt="Warning" style={{width: '20%', height: "auto"}}/>
                    <Text type="h200"><FormattedMessage id = {'packaging_not_returned'}/></Text>
                    <Text type="h100"><FormattedMessage id={"debrief_complete"} /></Text>
                    <Text type="b200" alignment="center"><FormattedMessage id = {'packaging_not_returned_details'}/></Text>
                </Column> :
                <Column alignmentHorizontal="center" spacingInset="small">
                    <img src={imageSuccess} alt="Successful" style={{width: '20%', height: "auto"}}/>
                    <Text type="h200"><FormattedMessage id={'packaging_returned'}/></Text>
                    <Text type="h100"><FormattedMessage id={"debrief_complete"}/></Text>
                </Column>
            }
            <Column alignmentHorizontal="center" alignmentVertical="bottom">
                <Row width="100%" widths="fill" alignmentVertical="bottom">
                    <Button onClick={driverSummary} size="large" type="primary"><FormattedMessage id={"btn_view_return_summary"} /></Button>
                </Row>
            </Column>
        </Column>
    )
}

export default DunnageReceiveSuccessView;